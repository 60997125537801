@import '@objectedge/pecai-storefront-ds/src/styles/variables';

.loginposition {
  order: 1;
  @include media-breakpoint-down(sm) {
    order: 2;
  }
}

.registerposition {
  order: 2;
  @include media-breakpoint-down(sm) {
    order: 1;
  }
}
