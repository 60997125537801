@import '@objectedge/pecai-storefront-ds/src/styles/colors/colors';
@import '@objectedge/pecai-storefront-ds/src/styles/variables';

.loginLayout {
  background-color: $light;
  flex-grow: 1;

  @include media-breakpoint-up(md) {
    padding: 32px 0;
  }
}
