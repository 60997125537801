@import '@objectedge/pecai-storefront-ds/src/styles/variables';

.loginForm {
  @include media-breakpoint-up(md) {
    background-color: $white;
    border: 1px solid $grey;
    padding: 52px 95px;
  }

  @include media-breakpoint-down(lg) {
    padding: 24px;
  }
}
